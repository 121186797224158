import { Text } from 'components/Text/Text'
import styles from './Statistics.module.scss'

type StatisticsProps = {
  title: string
  items: {
    value: string
    description: string
  }[]
}

export const Statistics = ({ title, items }: StatisticsProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Text type="h2" className={styles.title} weight="bold" align="center">
          {title}
        </Text>
        <div className={styles.dataInfoContainer}>
          {items.map(({ value, description }) => (
            <div key={`${value}-${description}`} className={styles.dataInfoItem}>
              <Text type="h3" color="red-500" weight="bold" align="center" className={styles.title}>
                {value}
              </Text>
              <Text type="h3" weight="bold" align="center">
                {description}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
