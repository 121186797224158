import { Typography, TypographyType } from 'components/DataDisplay/Typography/Typography'
import { FC } from 'react'

import styles from './Article.module.scss'

export type ArticleProps = {
  logoUrl: string
  logoAlternativeText: string
  title: string
  description: string
  articleUrl: string
}

export const Article: FC<ArticleProps> = ({ logoUrl, logoAlternativeText, title, description, articleUrl }) => {
  return (
    <a href={articleUrl} target="_blank" className={styles.article} rel="noreferrer">
      <img src={logoUrl} alt={logoAlternativeText} className={styles.articleLogo} />
      <Typography typographyType={TypographyType.H2_TITLE} className={styles.articleTitle}>
        {title}
      </Typography>
      <Typography typographyType={TypographyType.PARAGRAPH} className={styles.articleDescription}>
        {description}
      </Typography>
    </a>
  )
}
