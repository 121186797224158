import React, { FC } from 'react'
import classNames from 'classnames'
import { Text } from 'components/Text/Text'
import styles from './Partners.module.scss'

type PartnersProps = {
  title?: string
  className?: string
}

export const Partners: FC<PartnersProps> = ({ title, className }) => {
  return (
    <div className={styles.root}>
      <Text align="center" type="h2" weight="bold" className={styles.title}>
        {title}
      </Text>
      <section className={classNames(className, styles.partnersContainer)}>
        <div>
          <img src="/images/logos/ministry.webp" alt="Ministerio de Industria, Comercio y Turismo" />
        </div>
        <div>
          <img src="/images/logos/cupscapital.webp" alt="Ministerio de Industria, Comercio y Turismo" />
        </div>
        <div>
          <img src="/images/logos/lanzadera.webp" alt="Lanzadera" />
        </div>
        <div>
          <img src="/images/logos/dayone.webp" alt="Dayone" />
        </div>
        <div>
          <img src="/images/logos/expansion.webp" alt="Expansión" />
        </div>
        <div>
          <img src="/images/logos/startupeurope.webp" alt="Startup Europe" />
        </div>
        <div>
          <img src="/images/logos/metlife.webp" alt="Metlife Foundation" />
        </div>
        <div>
          <img src="/images/logos/proptech.webp" alt="Proptech" />
        </div>
        <div>
          <img src="/images/logos/finnova.webp" alt="Finnova" />
        </div>
        <div>
          <img src="/images/logos/village.webp" alt="Village Capital" />
        </div>
        <div>
          <img src="/images/logos/europeawards.webp" alt="Startup Europe Awards" />
        </div>
        <div>
          <img src="/images/logos/paypal.webp" alt="Paypal" />
        </div>
      </section>
    </div>
  )
}
