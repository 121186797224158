import { Slider } from 'components/DataDisplay/Slider/Slider'
import { Typography, TypographyType } from 'components/DataDisplay/Typography/Typography'
import { useTranslation } from 'hooks/useTranslation'
import { useEffect, useState } from 'react'

import { Article } from './Article/Article'
import styles from './Articles.module.scss'

const articlesList = [
  {
    logoUrl: '/images/articles-providers-logos/forbes.webp',
    articleUrl: 'https://forbes.es/nombramientos/365250/juan-velayos-ex-ceo-de-neinor-homes-se-incorpora-a-libeen-al-frente-de-las-inversiones',
    logoAlternativeText: 'Forbes',
    title: '“Juan Velayos (ex-CEO de Neinor Homes) se incorpora a Libeen”',
    description:
      'Velayos entra en Libeen «para acelerar el crecimiento y llevarla al siguiente nivel, ofreciendo la solución de acceso a la vivienda en las principales ciudades de España».',
  },
  {
    logoUrl: '/images/articles-providers-logos/expansion.webp',
    articleUrl: 'https://www.expansion.com/expansion-empleo/emprendedores/2022/04/06/624d25e5468aeb79148b4690.html',
    logoAlternativeText: 'Expansión',
    title: "“Libeen recauda 2,5 millones para facilitar el acceso a la vivienda a los 'millennials'”",
    description:
      "La 'proptech' ofrece el 'smart housing', un nuevo modelo de alquiler con opción a compra  para que las generaciones más jóvenes tengan un mayor acceso a la vivienda.",
  },
  {
    logoUrl: '/images/articles-providers-logos/elespanol.webp',
    articleUrl:
      'https://www.elespanol.com/invertia/empresas/inmobiliario/20231113/juan-velayos-exceo-neinor-homes-entra-libeen-sacar-bolsa-socimi/809169171_0.html',
    logoAlternativeText: 'El Español',
    title: '“Juan Velayos, exCEO de Neinor Homes, entra en Libeen para sacar a bolsa su socimi”',
    description: "Llega como inversor y presidente del comité de Inversión de la startup, creadora del concepto 'smarthousing'.",
  },
  {
    logoUrl: '/images/articles-providers-logos/cincodias.webp',
    articleUrl: 'https://cincodias.elpais.com/cincodias/2022/04/26/midinero/1650971263_141928.html',
    logoAlternativeText: 'Cinco Dias',
    title: '“Libeen recupera el alquiler con opción a compra y tiene 25.000 usuarios en lista de espera”',
    description:
      'El principal propósito de Libeen es atajar esta problemática tan arraigada en la generación millennial, aunque su propuesta no tiene límite de edad y es para todos los públicos.',
  },
  {
    logoUrl: '/images/articles-providers-logos/ejeprime.webp',
    articleUrl: 'https://www.ejeprime.com/empresa/juan-velayos-entra-en-la-proptech-libeen-para-impulsar-su-salida-a-bolsa',
    logoAlternativeText: 'Ejeprime',
    title: '“Juan Velayos entra en la ‘proptech’ Libeen para impulsar su salida a bolsa”',
    description:
      'Libeen es una startup liderada por José Manuel Cartes y Sofía Iturbe especializada en el SmartHousing: el usuario paga una pequeña entrada y se utiliza el dinero del alquiler, para comprar la vivienda.',
  },
  {
    logoUrl: '/images/articles-providers-logos/emprendedores.webp',
    articleUrl: 'https://www.emprendedores.es/oportunidades-de-negocio/smarthousing/',
    logoAlternativeText: 'Emprendedores',
    title: '“Smarthousing: la fórmula para comprar una vivienda sin ahorros”',
    description:
      'La startup Libeen quiere dar acceso al mercado de la vivienda con su propuesta de rent to by, que permite ahorrar el 20% del precio en los primeros cinco años de alquiler.',
  },
  {
    logoUrl: '/images/articles-providers-logos/ejeprime.webp',
    articleUrl: 'https://www.ejeprime.com/empresa/la-proptech-madrilena-libeen-cierra-una-ronda-de-25-millones-de-euros-con-cusp-capital.html',
    logoAlternativeText: 'Ejeprime',
    title: '“La ‘proptech’ madrileña Libeen cierra una ronda de 2,5 millones de euros con Cusp Capital”',
    description:
      'La start up se especializa en el proceso de compra de una vivienda mediante una fórmula que destina parte del pago de la renta de alquiler a cubrir la entrada de esta vivienda.',
  },
  {
    logoUrl: '/images/articles-providers-logos/negocios.webp',
    articleUrl: 'http://revistanegocios.es/j-manuel-cartes-ceo-libeen-en-libeen-estamos-reinventando-la-forma-en-como-compramos-una-vivienda/',
    logoAlternativeText: 'Negocios',
    title: '“En Libeen estamos reinventado la forma en cómo compramos una vivienda”',
    description:
      'Independizarse: esa palabra que ilusiona y aterra a partes iguales. Y es que para los jóvenes cada vez es más complicado poder adquirir una vivienda antes de los 30 años.',
  },
  {
    logoUrl: '/images/articles-providers-logos/urbanitae.webp',
    articleUrl: 'https://blog.urbanitae.com/2022/04/07/libeen-para-los-millennials-es-casi-imposible-convertirse-en-propietarios/',
    logoAlternativeText: 'Urbanitae',
    title: '“Libeen: Para los ‘millennials’ es casi imposible convertirse en propietarios”',
    description:
      'Contamos con una wait list de más de 26.000 personas interesadas en comprar su casa con nuestro modelo. Estaremos en las principales ciudades de España y avanzaremos por toda Europa.',
  },
]

export const Articles = () => {
  const { t } = useTranslation()

  /**
   * It's necessary prevent a render in mount component step
   * becase it cause a bad order for slides
   */
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  const SLIDER_OPTIONS = {
    infinite: true,
    centerMode: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    variableWidth: false,
    adaptiveHeight: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  }

  return (
    <div className={`${styles.articlesContainer} ${styles.container}`}>
      <Typography typographyType={TypographyType.H2_TITLE} className={styles.title}>
        {t('home:articles.title')}
      </Typography>

      <Typography typographyType={TypographyType.PARAGRAPH} className={styles.subtitle}>
        {t('home:articles.subtitle')}
      </Typography>

      <div className={styles.sliderContainer}>
        {!loading && (
          <Slider options={SLIDER_OPTIONS}>
            {articlesList.map((article) => (
              <Article
                key={article.title}
                logoUrl={article.logoUrl}
                articleUrl={article.articleUrl}
                logoAlternativeText={article.logoAlternativeText}
                title={article.title}
                description={article.description}
              />
            ))}
          </Slider>
        )}
      </div>
    </div>
  )
}
